<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Modifica vino {{ wine ? wine.name : '' }}</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'inventory.wines' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <WineForm v-if="wine" :value="wine" :feedback.sync="feedback" @submitForm="saveWine" edit />
    </div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import WineForm from '@/views/components/Form/WineForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    WineForm,
  },
  data () {
    return {
      wine: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'inventory.wines' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveWine (wineForm) {
      const wine = { ...wineForm };

      this.$api.updateWine(wine.id, wine)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    $api.fetchWine(to.params.id)
      .then(res => {
        return next(vm => {
          vm.wine = res?.data || {};
        });
      })
      .catch(err => {
        return next(vm => vm.$log.error(err));
      })
    ;
  },
};

</script>
